import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDiscord,
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { IconDefinition, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import GlobalClientConfig from 'HiveClient/config/GlobalClientConfig/GlobalClientConfig';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export type Props = {
  className?: string;
};

const SocialIcon = ({
  type,
  value,
  tooltip,
  icon,
}: {
  type: string;
  value: string;
  tooltip?: string;
  icon: IconDefinition;
}) =>
  tooltip ? (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id='type'>{tooltip}</Tooltip>}
    >
      <a href={value} target='blank' key={type}>
        <FontAwesomeIcon
          icon={icon}
          className={`social-icon ${type} rtl-maintain-orientation`}
        />
      </a>
    </OverlayTrigger>
  ) : (
    <a href={value} target='blank' key={type}>
      <FontAwesomeIcon
        icon={icon}
        className={`social-icon ${type} rtl-maintain-orientation`}
      />
    </a>
  );

const SocialLinks = (props: Props) => (
  <section className={props.className}>
    {GlobalClientConfig.socialLinks.map(({ type, value, tooltip }) => {
      switch (type) {
        case 'facebook':
          return (
            <SocialIcon
              type={type}
              value={value}
              tooltip={tooltip}
              icon={faFacebookSquare}
            />
          );
        case 'twitter':
          return (
            <SocialIcon
              type={type}
              value={value}
              tooltip={tooltip}
              icon={faTwitter}
            />
          );
        case 'youtube':
          return (
            <SocialIcon
              type={type}
              value={value}
              tooltip={tooltip}
              icon={faYoutube}
            />
          );
        case 'instagram':
          return (
            <SocialIcon
              type={type}
              value={value}
              tooltip={tooltip}
              icon={faInstagram}
            />
          );
        case 'discord':
          return (
            <SocialIcon
              type={type}
              value={value}
              tooltip={tooltip}
              icon={faDiscord}
            />
          );
        case 'email':
          return (
            <SocialIcon
              type={type}
              value={value}
              tooltip={tooltip}
              icon={faEnvelope}
            />
          );
        default:
          break;
      }
      return <></>;
    })}
  </section>
);

export default SocialLinks;
